import React, {Component} from "react";
import logoLight from './md-logo-light-color-v.svg';
import logoDark from './md-logo-dark-color-v.svg';
import './style.scss';
import EmojiCat from './EmojiCat';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
        darkMode: false,
    };
  }

  handleEmojiClick (e) {
    e.preventDefault();
    e.stopPropagation();
    const currentState = this.state.darkMode;
    this.setState({ darkMode: !currentState });
  }

  render() {
    return (
      <div className={`App${this.state.darkMode ? " darkmode" : ""}`}>
        <header className="App-header">
          <img
            src={this.state.darkMode ? logoDark : logoLight}
            className="App-logo"
            alt="meooow.design Logo"
          />
          <p className="emoji-text" onClick={(e) => this.handleEmojiClick(e)}>
            <EmojiCat />
          </p>
          <small>&copy;meooow design</small>
        </header>
      </div>
    )
  }
}

export default App;
